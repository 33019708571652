import React, { useEffect, useState } from "react";
import { GeneralService } from "../../services";
import { getLanguage } from "../../i18n";
import { useTranslation } from "react-i18next";
import locales from "../../locales";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar } from '@mui/material';

const RevocationsComponent = () => {
  const [revocationsData, setRevocationsData] = useState([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useParams();
    const [data] = useState([]);

  useEffect(() => {
    const fetchRevocations = async () => {
      try {
        const data = await GeneralService.getRevocations();
        setRevocationsData(data);
      } catch (error) {
        console.error("Error fetching revocations data:", error);
      }
    };

    fetchRevocations();
  }, []);

    function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    useEffect(() => {
        changeLanguage(getLanguage());
        document.body.style.overflow = 'hidden';
    }, [])
    const changeLanguage = (lng) => {
        const path = location.pathname.split("/").join(" ").trim().split(" ");
        const arr = path;
        i18n.language === "tr" && arr.push("tr");
        let key = getKeyByValue(
            locales[i18n.language].routes,
            path.slice(0, i18n.language === "tr" ? 1 : 2).join("/")
        );

        if (key) {
            if (arr.length >= 3) {
                const getPath = data.find((e) => e.MainUrl === slug)[
                    lng === "tr" ? "UrlTr" : "UrlEn"
                ];
                const redirect = t(`routes.${key}`, { lng: lng }) + "/" + getPath;
                navigate(redirect);
            } else {
                const redirect = t(`routes.${key}`, { lng: lng });
                navigate('/' + redirect);
            }
        } else {
            i18n.changeLanguage(lng);
        }

        localStorage.setItem("language", lng);
    };
    return (
        <div>
            {/* Dil de�i�tirme butonlar� */}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                {i18n.language === "tr" ? (
                    <div
                        onClick={() => changeLanguage("en")}
                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                    >
                        <Avatar
                            sx={{
                                width: "16px",
                                height: "16px",
                                float: "left",
                                padding: "0px 5px",
                            }}
                            alt="English"
                            src="/document/images/usa.png"
                        />
                        English
                    </div>
                ) : (
                    <div
                        onClick={() => changeLanguage("tr")}
                        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                    >
                        <Avatar
                            sx={{
                                width: "16px",
                                height: "16px",
                                float: "left",
                                padding: "0px 5px",
                            }}
                            alt="T�rk�e"
                            src="/document/images/tr.png"
                        />
                        T�rk�e
                    </div>
                )}
            </div>

            {/* Revocations JSON verileri */}
            <pre>{JSON.stringify(revocationsData, null, 2)}</pre>
        </div>
    );
};

export default RevocationsComponent;
